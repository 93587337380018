import React from 'react';

const Community = () => {
  return (
    <>
      <iframe title="typeform-TVS02Ng3" id="typeform-full" width="100%" height="100%" frameborder="0" allow="camera; microphone; autoplay; encrypted-media;" src="https://form.typeform.com/to/TVS02Ng3"></iframe>
      <script type="text/javascript" src="https://embed.typeform.com/embed.js"></script>
    </>
  )
}

export default Community;
